
  import { defineComponent } from "vue";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import {mapActions, mapGetters} from 'vuex';
  import OverlaySpinner from '@/components/OverlaySpinner.vue';
  import FacebookAdAccountsDropdown from '@/components/dropdown/FacebookAdAccountsDropdown.vue';
  import GoogleAdAccountsDropdown from '@/components/dropdown/GoogleAdAccountsDropdown.vue';
  import ConfirmDialog from '@/core/helpers/ConfirmDialog';
  import AddStoreBtn from '@/views/integrations/shopify/AddStoreBtn.vue';

  export default defineComponent({
    name: "ShopifyIntegration",

    data() {
      return {
        linkFacebookAccountDropdown: {},
        linkGoogleAccountDropdown: {},
      }
    },

    components: {
      OverlaySpinner,
      FacebookAdAccountsDropdown,
      GoogleAdAccountsDropdown,
      AddStoreBtn
    },

    computed: {
      ...mapGetters({
        shops: 'shopify/shops/list',
        loading: 'shopify/shops/listIsFetching',
      }),

      disabledDropdownFacebookAdAccounts(): string[]|null {
        if(!this.shops) return null;

        return this.shops
          .map(store => {
            if(!store.facebookAdAccounts || !store.facebookAdAccounts.length) return [];
            return store.facebookAdAccounts
              .map(facebookAdAccount => facebookAdAccount.id)
              .filter(facebookAdAccountId => facebookAdAccountId !== null);
          })
          .flat();
      },

      disabledDropdownGoogleAdAccounts(): string[]|null {
        if(!this.shops) return null;

        return this.shops
          .map(store => {
            if(!store.googleAdAccounts || !store.googleAdAccounts.length) return [];
            return store.googleAdAccounts
              .map(googleAdAccount => googleAdAccount.id)
              .filter(googleAdAccountId => googleAdAccountId !== null);
          })
          .flat();
      }
    },

    mounted() {
      setCurrentPageTitle("Shopify Integration");
      this.fetchShops({force: true});
    },

    methods: {
      ...mapActions({
        processLinkFacebookAdAccount: 'shopify/shops/linkFacebookAdAccount',
        processUnlinkFacebookAdAccount: 'shopify/shops/unlinkFacebookAdAccount',
        processLinkGoogleAdAccount: 'shopify/shops/linkGoogleAdAccount',
        processUnlinkGoogleAdAccount: 'shopify/shops/unlinkGoogleAdAccount',
        fetchShops: 'shopify/shops/fetch',
        processToggleActiveState: 'shopify/shops/toggleActiveState',
        processDelete: 'shopify/shops/delete',
      }),

      toggleItem(item) {
        this.processToggleActiveState({shopId: item.id})
      },

      // Facebook
      isLinkFacebookAccountDropdownVisible(storeId): boolean {
        if(!(storeId in this.linkFacebookAccountDropdown)) return false;
        return this.linkFacebookAccountDropdown[storeId];
      },
      togglelinkFacebookAccountDropdown(storeId, state:boolean|null = null) {
        if(state === null) state = !this.isLinkFacebookAccountDropdownVisible(storeId);
        this.linkFacebookAccountDropdown[storeId] = state;
      },

      linkFacebookAdAccount(shopId, adAccountId) {
        this.togglelinkFacebookAccountDropdown(shopId, false);
        this.processLinkFacebookAdAccount({shopId, adAccountId});
      },

      unlinkFacebookAdAccount(shopId, adAccountId) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Unlink Ad Account', type: 'error', confirmButtonText: 'Unlink', icon: 'warning'})
          .then(confirmed => {
            if (!confirmed) return;
            this.processUnlinkFacebookAdAccount({shopId, adAccountId});
          });
      },

      // Google
      isLinkGoogleAccountDropdownVisible(storeId): boolean {
        if(!(storeId in this.linkGoogleAccountDropdown)) return false;
        return this.linkGoogleAccountDropdown[storeId];
      },
      togglelinkGoogleAccountDropdown(storeId, state:boolean|null = null) {
        if(state === null) state = !this.isLinkGoogleAccountDropdownVisible(storeId);
        this.linkGoogleAccountDropdown[storeId] = state;
      },

      linkGoogleAdAccount(shopId, adAccountId) {
        this.togglelinkGoogleAccountDropdown(shopId, false);
        this.processLinkGoogleAdAccount({shopId, adAccountId});
      },

      unlinkGoogleAdAccount(shopId, adAccountId) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Unlink Ad Account', type: 'error', confirmButtonText: 'Unlink', icon: 'warning'})
        .then(confirmed => {
          if (!confirmed) return;
          this.processUnlinkGoogleAdAccount({shopId, adAccountId});
        });
      },


      deleteShop(item) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Delete Shopify shop', type: 'error', confirmButtonText: 'Delete', icon: 'warning'})
          .then(confirmed => {
            if (confirmed) {
              this.processDelete({id: item.id});
            }
          });
      },
    },
  });
