<template>
  <div class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Shops</span>
      </h3>
      <div class="card-toolbar">
        <add-store-btn/>
      </div>
    </div>

    <div class="card-body py-3">
      <div class="table-responsive">
        <table class="table table-row-bordered align-middle gy-5 gx-0">
          <thead>
          <tr class="fw-bold fs-6 text-gray-900 border-bottom-2 border-gray-200">
            <th class="min-w-180px">Shop</th>
            <th class="min-w-200px">Facebook Ad Account</th>
            <th class="min-w-200px">Google Ad Account</th>
            <th class="max-w-100px"></th>
            <th class="w-50px"></th>
          </tr>
          </thead>

          <overlay-spinner v-if="loading"/>

          <tbody v-else>
          <tr v-for="(shop, index) in shops" :key="`shop-row-${index}`" class="ad-account">
            <td>
              <div class="symbol symbol-50px me-4 d-inline-block">
                <label class="form-check form-switch form-check-custom form-check-solid" @click="toggleItem(shop)">
                  <input class="form-check-input" type="checkbox" value="" :checked="shop.is_active"/>
                </label>
              </div>
              <div class="d-inline-block">
                <div class="text-dark fw-bolder text-hover-primary mb-1 fs-6">{{ shop.shop_name }}</div>
                <span class="text-muted fw-bold d-block">{{ shop.shopify_domain }}</span>
              </div>
            </td>
            <td>
              <div v-if="shop.facebookAdAccounts && shop.facebookAdAccounts.length">
                <div v-for="facebookAdAccount in shop.facebookAdAccounts"
                     :key="`shop-${shop.id}-facebook-ad-account-${facebookAdAccount.id}`">

                  <span class="text-dark fw-bolder text-hover-primary">
                    {{ facebookAdAccount.name }}
                  </span>

                  <span class="ms-1 text-muted fw-bold">{{ facebookAdAccount.id }}</span>

                  <span class="ms-5 ad-account-actions"
                        :class="{'invisible': isLinkFacebookAccountDropdownVisible(shop.id)}">
                    <i class="fas fa-unlink cursor-pointer text-hover-primary" @click="unlinkFacebookAdAccount(shop.id, facebookAdAccount.id)"></i>
                  </span>
                </div>
              </div>

              <div :class="{'ad-account-action-attach': (shop.facebookAdAccounts && shop.facebookAdAccounts.length)}">
                <el-button v-if="!isLinkFacebookAccountDropdownVisible(shop.id)"
                           type="text"
                           size="medium"
                           @click="togglelinkFacebookAccountDropdown(shop.id, true)">
                  {{ (shop.facebookAdAccounts && shop.facebookAdAccounts.length) ? 'Attach Account' : 'Link Account'}}
                </el-button>
              </div>


              <div v-if="isLinkFacebookAccountDropdownVisible(shop.id)">
                <facebook-ad-accounts-dropdown @escape="togglelinkFacebookAccountDropdown(shop.id, false)"
                                      @update="linkFacebookAdAccount(shop.id, $event)"
                                      :disabled-values="disabledDropdownFacebookAdAccounts"/>
              </div>
            </td>
            <td>
              <div v-if="shop.googleAdAccounts && shop.googleAdAccounts.length">
                <div v-for="googleAdAccount in shop.googleAdAccounts"
                     :key="`shop-${shop.id}-google-ad-account-${googleAdAccount.id}`">

                  <span class="text-dark fw-bolder text-hover-primary">
                    {{ googleAdAccount.descriptive_name }}
                  </span>

                  <span class="ms-1 text-muted fw-bold">{{ googleAdAccount.id }}</span>

                  <span class="ms-5 ad-account-actions"
                        :class="{'invisible': isLinkGoogleAccountDropdownVisible(shop.id)}">
                    <i class="fas fa-unlink cursor-pointer text-hover-primary" @click="unlinkGoogleAdAccount(shop.id, googleAdAccount.id)"></i>
                  </span>
                </div>
              </div>

              <div :class="{'ad-account-action-attach': (shop.googleAdAccounts && shop.googleAdAccounts.length)}">
                <el-button v-if="!isLinkGoogleAccountDropdownVisible(shop.id)"
                           type="text"
                           size="medium"
                           @click="togglelinkGoogleAccountDropdown(shop.id, true)">
                  {{ (shop.googleAdAccounts && shop.googleAdAccounts.length) ? 'Attach Account' : 'Link Account'}}
                </el-button>
              </div>


              <div v-if="isLinkGoogleAccountDropdownVisible(shop.id)">
                <google-ad-accounts-dropdown @escape="togglelinkGoogleAccountDropdown(shop.id, false)"
                                      @update="linkGoogleAdAccount(shop.id, $event)"
                                      :disabled-values="disabledDropdownGoogleAdAccounts"/>
              </div>
            </td>
            <td>
              <span class="badge badge-danger me-2" v-if="shop.status === 'DISABLED'">Disabled</span>
            </td>
            <td class="text-end">
              <i class="fas fa-trash-alt fs-3 cursor-pointer text-hover-danger delete-shop-btn me-8" @click="deleteShop(store)"></i>
            </td>
          </tr>

          <tr v-if="shops.length === 0">
            <td colspan="4" class="text-center">
              <div class="alert alert-secondary">There are no items to show</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import {mapActions, mapGetters} from 'vuex';
  import OverlaySpinner from '@/components/OverlaySpinner.vue';
  import FacebookAdAccountsDropdown from '@/components/dropdown/FacebookAdAccountsDropdown.vue';
  import GoogleAdAccountsDropdown from '@/components/dropdown/GoogleAdAccountsDropdown.vue';
  import ConfirmDialog from '@/core/helpers/ConfirmDialog';
  import AddStoreBtn from '@/views/integrations/shopify/AddStoreBtn.vue';

  export default defineComponent({
    name: "ShopifyIntegration",

    data() {
      return {
        linkFacebookAccountDropdown: {},
        linkGoogleAccountDropdown: {},
      }
    },

    components: {
      OverlaySpinner,
      FacebookAdAccountsDropdown,
      GoogleAdAccountsDropdown,
      AddStoreBtn
    },

    computed: {
      ...mapGetters({
        shops: 'shopify/shops/list',
        loading: 'shopify/shops/listIsFetching',
      }),

      disabledDropdownFacebookAdAccounts(): string[]|null {
        if(!this.shops) return null;

        return this.shops
          .map(store => {
            if(!store.facebookAdAccounts || !store.facebookAdAccounts.length) return [];
            return store.facebookAdAccounts
              .map(facebookAdAccount => facebookAdAccount.id)
              .filter(facebookAdAccountId => facebookAdAccountId !== null);
          })
          .flat();
      },

      disabledDropdownGoogleAdAccounts(): string[]|null {
        if(!this.shops) return null;

        return this.shops
          .map(store => {
            if(!store.googleAdAccounts || !store.googleAdAccounts.length) return [];
            return store.googleAdAccounts
              .map(googleAdAccount => googleAdAccount.id)
              .filter(googleAdAccountId => googleAdAccountId !== null);
          })
          .flat();
      }
    },

    mounted() {
      setCurrentPageTitle("Shopify Integration");
      this.fetchShops({force: true});
    },

    methods: {
      ...mapActions({
        processLinkFacebookAdAccount: 'shopify/shops/linkFacebookAdAccount',
        processUnlinkFacebookAdAccount: 'shopify/shops/unlinkFacebookAdAccount',
        processLinkGoogleAdAccount: 'shopify/shops/linkGoogleAdAccount',
        processUnlinkGoogleAdAccount: 'shopify/shops/unlinkGoogleAdAccount',
        fetchShops: 'shopify/shops/fetch',
        processToggleActiveState: 'shopify/shops/toggleActiveState',
        processDelete: 'shopify/shops/delete',
      }),

      toggleItem(item) {
        this.processToggleActiveState({shopId: item.id})
      },

      // Facebook
      isLinkFacebookAccountDropdownVisible(storeId): boolean {
        if(!(storeId in this.linkFacebookAccountDropdown)) return false;
        return this.linkFacebookAccountDropdown[storeId];
      },
      togglelinkFacebookAccountDropdown(storeId, state:boolean|null = null) {
        if(state === null) state = !this.isLinkFacebookAccountDropdownVisible(storeId);
        this.linkFacebookAccountDropdown[storeId] = state;
      },

      linkFacebookAdAccount(shopId, adAccountId) {
        this.togglelinkFacebookAccountDropdown(shopId, false);
        this.processLinkFacebookAdAccount({shopId, adAccountId});
      },

      unlinkFacebookAdAccount(shopId, adAccountId) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Unlink Ad Account', type: 'error', confirmButtonText: 'Unlink', icon: 'warning'})
          .then(confirmed => {
            if (!confirmed) return;
            this.processUnlinkFacebookAdAccount({shopId, adAccountId});
          });
      },

      // Google
      isLinkGoogleAccountDropdownVisible(storeId): boolean {
        if(!(storeId in this.linkGoogleAccountDropdown)) return false;
        return this.linkGoogleAccountDropdown[storeId];
      },
      togglelinkGoogleAccountDropdown(storeId, state:boolean|null = null) {
        if(state === null) state = !this.isLinkGoogleAccountDropdownVisible(storeId);
        this.linkGoogleAccountDropdown[storeId] = state;
      },

      linkGoogleAdAccount(shopId, adAccountId) {
        this.togglelinkGoogleAccountDropdown(shopId, false);
        this.processLinkGoogleAdAccount({shopId, adAccountId});
      },

      unlinkGoogleAdAccount(shopId, adAccountId) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Unlink Ad Account', type: 'error', confirmButtonText: 'Unlink', icon: 'warning'})
        .then(confirmed => {
          if (!confirmed) return;
          this.processUnlinkGoogleAdAccount({shopId, adAccountId});
        });
      },


      deleteShop(item) {
        ConfirmDialog.show({text: 'Are you sure?', title: 'Delete Shopify shop', type: 'error', confirmButtonText: 'Delete', icon: 'warning'})
          .then(confirmed => {
            if (confirmed) {
              this.processDelete({id: item.id});
            }
          });
      },
    },
  });
</script>

<style scoped>
  tr.ad-account .ad-account-actions,
  tr.ad-account .ad-account-action-attach,
  tr.ad-account .delete-shop-btn {
    visibility: hidden;
  }
  tr.ad-account:hover .ad-account-actions,
  tr.ad-account:hover .ad-account-action-attach,
  tr.ad-account:hover .delete-shop-btn {
    visibility: visible;
  }
</style>
