
  import {defineComponent} from 'vue';
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import store from '@/store';
  import {Actions} from '@/store/enums/StoreEnums';
  import {mapActions} from "vuex";

  export default defineComponent({
    name: "AddStoreModal",

    data() {
      return {
        buttonWasClicked: false,
      };
    },

    methods: {
      ...mapActions({
        fetchShops: 'shopify/shops/fetch',
      }),

      addStore() {
        this.buttonWasClicked = true;
        let popup, popupTick;
        let state = 'initial';

        Swal.fire({
          title: 'Enter your Shopify shop domain and then please install this app',
          icon: 'info',
          input: 'text',
          inputPlaceholder: 'example.myshopify.com',
          inputAttributes: {
            autocapitalize: 'off',
          },
          buttonsStyling: false,
          showCancelButton: true,
          customClass: {
            confirmButton: "btn fw-bold btn-primary",
            cancelButton: "btn fw-bold btn-secondary",
            icon: 'text-primary border-primary',
          },
          confirmButtonText: 'Add Store',
          focusConfirm: false,
          inputValidator: value => {
            return (value.includes('.myshopify.com')) ? null : 'Invalid shop url';
          },
          preConfirm: shopUrl => {
            if (state === 'install-app') {
              return 'done';
            }

            const self = this;

            return new Promise(() => {
              const shopifyAppAuthUrl = process.env.VUE_APP_SHOPIFY_APP_AUTHENTICATE_URL;
              popup = window.open(`${shopifyAppAuthUrl}?shop=${shopUrl}`, 'Adding Shopify Store');
              state = 'install-app';

              if (popup) {
                Swal.enableButtons();
                popup.focus();

                const content = (document.querySelector('.swal2-content') as HTMLElement);

                if (content) content.style.display = 'none';
                Swal.getTitle().textContent = 'Click "Done" if you have finished installing app on your Shopify store';
                Swal.getConfirmButton().textContent = 'Done';
                Swal.getCancelButton().textContent = 'Abort';

                popupTick = setInterval(() => {
                  if (popup.closed) {
                    clearInterval(popupTick);
                    Swal.close();
                    self.reloadStores();
                  }
                }, 500);
              }
            });
          },
          allowOutsideClick: () => state === 'initial',
          allowEscapeKey: () => state === 'initial',
        })
          .then(result => {
            clearInterval(popupTick);

            if (result.value === 'done' && result.isConfirmed) {
              this.reloadStores();
            }
            // cleanup on "Abort"
            else if (result.dismiss === Swal.DismissReason.cancel && Swal.getCancelButton().textContent === 'Abort') {
              popup.close();
              this.buttonWasClicked = false;
            }
          });
      },

      reloadStores() {
        if (!document.hidden) {
          this.buttonWasClicked = false;
          this.fetchShops({force: true});
        }
      },
    },
  });
