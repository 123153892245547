<template>
  <el-select
    class="form-select-solid"
    :placeholder="placeholder"
    v-model="selection"
    :loading="dropdownListIsLoading"
    @change="selectionChanged"
    @keyup.esc="$emit('escape')"
  >
    <el-option v-for="(item, index) in dropdownList"
               :label="item.label"
               :value="item.value"
               :key="`ad-account-option-${index}`"
               :disabled="isDropdownOptionDisabled(item.value)"
    ></el-option>
  </el-select>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import {mapActions, mapGetters} from 'vuex';

  export default defineComponent({
    name: "GoogleAdAccountsDropdown",

    props: {
      value: {
        default: null,
      },
      disabledValues: {
        type: Array,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Select Ad Account',
      },
    },

    data() {
      return {
        selection: null,
      };
    },

    computed: {
      ...mapGetters({
        dropdownList: 'google/dropdown/adAccounts/list',
        dropdownListIsLoading: 'google/dropdown/adAccounts/listIsFetching',
      }),
    },

    mounted() {
      this.selection = this.value;

      this.fetchDropdownList();
    },

    methods: {
      ...mapActions({
        fetchDropdownList: 'google/dropdown/adAccounts/fetch',
      }),
      selectionChanged(value) {
        this.$emit('update', value);
      },
      isDropdownOptionDisabled(value) {
        if(!this.disabledValues) return false;
        if(value === this.selection) return false;

        return this.disabledValues.includes(value);
      }
    },
  });
</script>
