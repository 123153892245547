
  import {defineComponent} from 'vue';
  import {mapActions, mapGetters} from 'vuex';

  export default defineComponent({
    name: "FacebookAdAccountsDropdown",

    props: {
      value: {
        default: null,
      },
      disabledValues: {
        type: Array,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Select Ad Account',
      },
    },

    data() {
      return {
        selection: null,
      };
    },

    computed: {
      ...mapGetters({
        dropdownList: 'facebook/dropdown/adAccounts/list',
        dropdownListIsLoading: 'facebook/dropdown/adAccounts/listIsFetching',
      }),
    },

    mounted() {
      this.selection = this.value;

      this.fetchDropdownList();
    },

    methods: {
      ...mapActions({
        fetchDropdownList: 'facebook/dropdown/adAccounts/fetch',
      }),
      selectionChanged(value) {
        this.$emit('update', value);
      },
      isDropdownOptionDisabled(value) {
        if(!this.disabledValues) return false;
        if(value === this.selection) return false;

        return this.disabledValues.includes(value);
      }
    },
  });
